<template>
    <div class="spike-notification"
         :class="{expanded, isNew, 'has-tags': hasTags, 'spike-notification--deleted': notification.deleted}"
         @keydown.esc.stop="expand(false)"
         tabindex="-1"
         ref="card">
        <header>
            <h4>
                <rpcs-tag v-if="isRpcsFlag(tag)" :code="tag"></rpcs-tag>
                <span v-if="notification.name && !nameEditMode"
                      class="spike-notification__name"
                      @click.stop="editName">
                    {{notification.name}}
                    <i v-if="!isDefault" class="symbol-edit"></i>
                </span>
                <span v-else-if="!nameEditMode"
                      class="spike-notification__name spike-notification--missing"
                      @click.stop="editName">
                    «Edit name»
                    <i v-if="!isDefault" class="symbol-edit"></i>
                </span>

                <inline-text-input v-if="nameEditMode && !isDefault"
                                   v-model.trim="tempName"
                                   hide-controls
                                   placeholder="Name this notification"
                                   ref="nameEditInput"
                                   :disabled="!enabledAndEditable"
                                   @cancel="cancelEditName"
                                   @ok="updateName"
                />
            </h4>
            <section class="spike-notification__expand"
                     v-if="!notification.deleted"
                     tabindex="-1"
                     @keydown.esc.stop="expand(false)"
                     @click.stop="expand()">
                <old-tooltip label="Click to see more details about this alert">
                    <i :class="this.expanded ? 'icon-angle-double-left' : 'icon-angle-double-right'"></i>
                </old-tooltip>
            </section>

            <span v-if="expanded && user.debugMode" class="spike-notification__debug">
                <popup-menu>
                    <template #activator>
                        <span>
                        🐛
                        </span>
                    </template>
                    <div class="mini-menu">
                        <ul>
                            <li>Debug options
                            <li>Notification id: {{notification.id}}</li>
                            <li>External id: {{notification.externalId}}</li>
                            <li>
                                Fired: {{logs.length}} times
                                <span v-if="logs.length">
                                    between {{formatDate(logs[logs.length - 1].date)}} <br>
                                    and {{formatDate(logs[0].date)}}
                                </span>
                            </li>
                        </ul>
                        <a @click="previewMentions">See mentions</a>
                        <a @click="previewInCorn">Preview in corn</a>
                    </div>
                </popup-menu>

            </span>

            <span v-if="expanded" class="spike-notification__close">
                <be-button link
                           tooltip="Click to close (esc)"
                           @click.stop="closeOrCancel()">
                    <i class="symbol-close"></i>
                </be-button>
            </span>
        </header>
        <section v-if="hasTags" class="spike-notification__tags">
            <slotted-tag v-if="!notification.active && !notification.deleted"
                         close-tooltip="Click to activate this notification"
                         :disabled="!enabledAndEditable"
                         @click.stop
                         @close="activateNotification">Inactive</slotted-tag>
            <slotted-tag v-if="notification.deleted"
                         @click.stop
                         no-close>Deleted</slotted-tag>
            <slotted-tag v-if="isNew"
                         tooltip="This notification has unsaved changed"
                         @click.stop
                         no-close>
                <i class="symbol-warning"></i>Unsaved changes
            </slotted-tag>
            <slotted-tag v-if="warnings.length && notification.active"
                         no-close
                         @click.stop="showWarnings"
                         tooltip="Click to see warnings" style="cursor: pointer">
                <warning-indicator :warnings="warnings"
                                   ref="warning"
                                   class="spike-notification__warnings"/>Has warnings
            </slotted-tag>
            <notification-saving class="spike-notification__loading"
                                 v-if="updating"/>
        </section>

        <section class="spike-notification__image">
            <section class="spike-notification__image-loader"
                     v-if="loadingSpike || spikeError || isNew">
                <spinner-component v-if="loadingSpike" :size="16"/>
                <span v-else-if="spikeError">
                    There was an error loading this image
                </span>
                <em v-else-if="isNew">
                    Setting up notification
                </em>
            </section>

            <section v-show="!loadingSpike && !spikeError"
                     @mouseover="showChartTooltip"
                     class="spike-notification__chart" ref="chart">
            </section>

        </section>
        <section class="spike-notification__description" :class="{'spike-notification__description--empty': !notification.description}">
            {{description}}
        </section>
        <section class="spike-notification__subscribers">
            <deq-number :number="numRecipientsInAccount"/> {{formatPlural(numRecipientsInAccount, "recipient")}}
        </section>
        <section v-if="!notification.deleted"
                 class="spike-notification__subscribe">
            <section v-if="!isSubscribed"
                     class="spike-notification__call-to-action"
                       @click.stop="createAndSubscribe"
                       tooltip="If you would like to receive an email for this alert, click here to subscribe to it"
                       :disabled="updating">
                Click to subscribe
            </section>
            <slotted-tag v-else
                         @close="unsubscribe"
                         @click.stop
                         :disabled="updating"
                         close-tooltip="Click to unsubscribe"
                         tooltip="You are currently subscribed to this notification">
                 <animated-check :animated="justSubscribed"/> Subscribed
            </slotted-tag>
        </section>
        <section class="spike-notification__details"
                 ref="details"
                 @click.stop>

            <section class="spike-notification__detail-scroll dark-scrollbars">
                <section v-if="!notification.externalId"
                         class="spike-notification__detail-description"
                         :class="{'spike-notification__detail-description--disabled': !enabledAndEditable}"
                         @click="editDescription">

                    <h4>What this alert does</h4>
                    <inline-text-input v-if="descriptionEditMode"
                                       v-model.trim="tempDescription"
                                       ref="descriptionEditInput"
                                       placeholder="Describe this alert"
                                       :disabled="!enabledAndEditable"
                                       @ok="updateDescription"
                                       @cancel="cancelEditDescription"/>
                    <section v-else>
                    <span v-if="notification.description">
                        {{notification.description}} <i class="symbol-edit"></i>
                    </span>
                        <span v-else
                              class="spike-notification__detail-no-description">
                        This alert has no description. Click to <i class="symbol-edit"></i> edit.
                    </span>
                    </section>
                </section>


                <section>
                    <h4>Recipients</h4>
                    <notification-recipients
                        ref="recipients"
                        :notification="notification"
                        @unsubscribe="unsubscribe()"
                        @subscribe="createAndSubscribe()"
                        @remove="removeRecipient($event)"
                        @add="createAndAddRecipient($event)"
                        :editable="enabledAndEditable"
                        :disabled="!enabled"
                    />
                </section>

                <h4>What mentions we track for this notification</h4>
                <notification-filter-editor
                    :notification="notification"
                    :disabled="!enabledAndEditable"
                    @update="updateFilterAndLoadChart($event)"/>

                <section v-if="showAdvanced">
                    <h4>Sensitivity and volume limits</h4>
                    <div>
                        <notification-bucket-selector :notification="notification"
                                                      :disabled="!enabledAndEditable"
                                                      @set-amount="setMinimumAlertSize($event)"
                                                      @set-bucket="setBucket($event)"/>
                    </div>
                </section>
            </section>



            <section v-if="!isDefault" class="spike-notification__danger" @click.stop>
                <be-button v-if="notification.active && !isNew && user.admin && !showAdvanced"
                           link
                           tooltip="Staff only: click here for advanced settings"
                           :disabled="!enabledAndEditable"
                           @click="showAdvanced = true">
                    Show advanced
                </be-button>
                <slotted-tag v-if="notification.active && !isNew && user.admin && showAdvanced"
                             :disabled="!enabledAndEditable"
                             @close="showAdvanced = false">
                    Showing advanced
                </slotted-tag>

                <be-button v-if="notification.active && !isNew"
                           tooltip="Click here if you would like to stop this notification from sending too all recipients"
                           :disabled="!enabledAndEditable"
                           @click="deactivateNotification()">
                    Deactivate
                </be-button>

                <be-button v-if="!notification.active && !isNew"
                           tooltip="Click here if you would like to start sending all recipients emails from this notification"
                           @click="activateNotification()"
                           :disabled="!enabledAndEditable">
                    Make active
                </be-button>
                <be-button v-if="!notification.active && !isNew"
                           :danger="true"
                           tooltip="Click here to permanently delete this notification"
                           @click="deleteNotification()"
                           :disabled="!enabledAndEditable">
                    Delete
                </be-button>

                <be-button v-if="isNew"
                           tooltip="Cancel the creation of this new notification"
                           @click="cancelCreation"
                           :disabled="updating">
                    Cancel
                </be-button>
                <be-button v-if="isNew"
                           primary
                           tooltip="Click here to save these settings and to start sending these notifications"
                           @click="saveAndLoad()"
                           :disabled="!canSave">
                    Save
                </be-button>
            </section>
            <section v-else class="spike-notification__message">
                <span class="deq-callout--muted">{{ defaultAlertMessage }}</span>
                <div class="spike-notification__default-show-advanced">
                    <be-button v-if="notification.active && !isNew && user.admin && !showAdvanced"
                               link
                               tooltip="Staff only: click here for advanced settings"
                               :disabled="!enabledAndEditable"
                               @click="showAdvanced = true">
                        Show advanced
                    </be-button>
                    <slotted-tag v-if="notification.active && !isNew && user.admin && showAdvanced"
                                 :disabled="!enabledAndEditable"
                                 @close="showAdvanced = false">
                        Showing advanced
                    </slotted-tag>
                </div>

            </section>
        </section>
    </div>

</template>

<script>
import BeButton from "@/components/buttons/BeButton";
import DeqNumber from "@/components/formatters/DeqNumber";
import {notificationMixin} from "./NotificationMixins";
import SlottedTag from "@/components/tags/SlottedTag";
import SpinnerComponent from "@/components/SpinnerComponent";
import {beef} from "@/store/Services";
import * as b3js from 'brandseyejs';
import WarningIndicator from "@/components/WarningIndicator";
import NotificationRecipients from "./NotificationRecipients";
import NotificationSaving from "./NotificationSaving";
import NotificationFilterEditor from "./NotificationFilterEditor";
import InlineTextInput from "@/components/inputs/InlineTextInput";
import OldTooltip from "@/components/tooltip/OldTooltip";
import {showTooltipComponent, showTooltipText} from "@/components/tooltip/TooltipUtilities";
import AlertNotificationTooltip from "./AlertNotificationTooltip";
import PopupMenu from "@/components/PopupMenu";
import AnimatedCheck from "@/components/animated-icons/AnimatedCheck";
import {codeToColour, isRpcsFlag} from "@/app/utils/Metatags";
import {showMentions} from "@/app/framework/dialogs/mentions/MentionsDialogUtilities";
import moment from "moment";
import {count as grouseCount} from "@/data/Grouse";
import {showErrorDialog} from "@/app/framework/dialogs/Dialog";
import RpcsTag from "@/components/tags/RpcsTag";
import {mapState} from "vuex";
import {formatDate, formatPlural} from "@/app/utils/Format";
import NotificationBucketSelector from "@/setup/notifications/NotificationBucketSelector";
import {checkVolumeSpikeNotifications} from "@/store/vuex/dataChecks";


export default {
        name: "SpikeNotification",
        mixins: [notificationMixin],
        components: {
            DeqNumber,
            OldTooltip,
            SpinnerComponent,
            NotificationBucketSelector,
            RpcsTag,
            AnimatedCheck,
            PopupMenu,
            InlineTextInput,
            NotificationFilterEditor,
            NotificationSaving,
            NotificationRecipients,
            WarningIndicator, SlottedTag, BeButton },
        props: {
            notification: {
                type: Object,
                required: true
            },
            logs: { // The logs that indicate when this alert was fired.
                type: Array
            }
        },

        data() {
            return {
                loadingSpike: false,
                spikeError: false,
                volumeSpikeData: [],
                chart: null,
                showAdvanced: false
            }
        },

        computed: {
            ...mapState(['account', 'user']),

            hasTags() {
                return !this.notification.active || this.notification.deleted || this.isNew || this.updating || !!this.warnings?.length;
            },

            recipientCount() {
                if (this.notification.deleted) return 0;
                return this.notification.recipients ? this.notification.recipients.length : 0
            },

            description() {
                return this.notification.description ?? "No description";
            },

            canSave() {
                return !this.updating &&
                    this.notification.name?.trim() &&
                    this.notification.description?.trim() &&
                    this.warnings?.length === 0;
            },

            chartTooltip() {
                switch (this.tag) {
                    case "RISK":
                        return "This is the volume of Risk related mentions from the last month for your own brands";
                    case "PURCHASE":
                        return "This is the volume of Purchase related mentions from the last month for your own brands";
                    case "CANCEL":
                        return "This is the volume of Cancel related mentions from the last month for your own brands";
                    case "SERVICE":
                        return `This is the volume of ${this.notification.name} from the last month for your own brands`;
                    default:
                        return "This is the volume of mentions matching this alert over the last month";

                }
            },

            defaultAlertMessage() {
                return "Send a mail when an unusually high mention volume is observed compared to the filter's historical trend";
            }
        },

        watch: {
            volumeSpikeData() {
                this.$nextTick(() => this.drawSpikeData());
            },

            hasTags() {
                this.$nextTick(() => this.drawSpikeData());
            },

            expanded(val, oldVal) {
                if (val !== oldVal) this.handleExpansion(val);
            },

            notification() {
                if (!this.notification.description) {
                    this.$set(this.notification, "description", null);
                }
            }
        },

        mounted() {
            this.chart = new b3js.chart();
            this.loadSpikeData();
            this.checkWarnings();
            this.handleExpansion(this.expanded, !this.isNew || this.notification.id);
        },

        methods: {
            formatPlural,
            formatDate,
            isRpcsFlag,

            showChartTooltip(event) {
                showTooltipText(event.target, this.chartTooltip);
            },

            closeOrCancel() {
                if (this.isNew) {
                    this.cancelCreation();
                    return;
                }

                this.expand(false);
            },

            handleExpansion(val, noAnimation) {
                if (!val && this.$refs.recipients) {
                    this.$refs.recipients.cancelEditMode();
                }
                
                const card = this.$refs.card;
                const details = this.$refs.details;
                if (!card || !details) return;

                // If we don't want to animate the expansion, we need
                // to remove some transition properties and add some no animation classes.
                // This is useful when we are creating a brand new notification, since
                // on save we recreate the vue object.
                const cardProps = card.style['transition-property'];
                if (noAnimation) {
                    card.style['transition-property'] = 'none';
                    details.classList.add("spike-notification__details--no-animation");
                }

                if (val) {
                    card.style['margin-left'] = `-${card.offsetLeft - 10}px`;
                    card.style.width = `${card.offsetParent.clientWidth - 40}px`;
                    setTimeout(
                        () => details.classList.add("spike-notification__details--visible"),
                        noAnimation ? 0 : 250);
                } else {
                    card.style['margin-left'] = "0";
                    card.style.width = '250px';
                    details.classList.remove("spike-notification__details--visible");
                    details.classList.remove("spike-notification__details--no-animation");
                }
                if (noAnimation) {
                    setTimeout(() => card.style['transition-property'] = cardProps, 300);
                }
            },

            async saveAndLoad() {
                await this.saveNew();
                await this.loadSpikeData(true);
            },

            async updateFilterAndLoadChart(filter) {
                await this.updateFilter(filter);
                await this.loadSpikeData(true)
            },

            showWarnings() {
                this.$refs.warning.showWarnings();
            },

            async drawSpikeData() {
                const chartDom = this.$refs.chart;
                if (!chartDom) return;

                if (!this.volumeSpikeData || !this.volumeSpikeData.length) {
                    chartDom.childNodes.forEach(c => chartDom.removeChild(c));
                    return;
                }

                const chart = this.chart
                    .reset()
                    .geometry(b3js.line())
                    .scaleX(b3js.scaleTime())
                    .data(this.volumeSpikeData)
                    .element(chartDom)
                    .x(d => d.published)
                    .y(d => d.count)
                    .width(chartDom.clientWidth)
                    .height(chartDom.clientHeight)
                    .showXAxis(false);

                if (this.tag) {
                    chart.individualColours((d, defaultColour) => {
                        return codeToColour(this.tag) ?? defaultColour;
                    })
                }

                const tag = this.tag;
                const notification = this.notification;

                chart.dispatch().on('tooltipShow', (event) => {
                    showTooltipComponent(event.e.currentTarget,
                        AlertNotificationTooltip,
                        {
                            data: event.point,
                            tag: tag,
                            name: tag === "SERVICE" ? notification.name : null
                        })
                })

                chart.dispatch().on('elementClick', data => {
                    const event = data.e || d3.event;
                    let newTab = false;
                    const filter = `(${notification.filter}) and published on '${data.point.published}'`;
                    switch (event.which) {
                        case 2:
                            // middle click
                            newTab = true;
                            break;
                        case 1:
                            if (event.metaKey) {
                               newTab = true;
                            }

                            if (!newTab) {
                                showMentions(filter, `${notification.name} on ${data.point.published} (by highest engagement)`, true);
                                return;
                            }

                            Beef.MentionList.navigateToMentions(
                                this.account.code,
                                filter,
                                null, true
                            );
                    }
                })

                chart.render();
            },
            /**
             * Removes data from today, to remove the dip at the end of every chart.
             * Also, maps all -1 values to 0.
             */
            cleanData(data) {
                const forecasts = data.forecast
                    .filter(d => moment(d.published)
                        .isBefore(moment()
                            .endOf('day')));

                for (const d of forecasts) {
                    if (d.count === -1 || d.count === null) d.count = 0;
                }

                return forecasts;
            },

            async loadSpikeData(force) {
                // Nothing to load for new notifications.
                if (this.isNew) return;

                // See if we have some cached data for this
                const key = this.notification.externalId || this.notification.id
                    ? `dataeq:cache:volumespike:${this.notification.externalId || this.notification.id}`
                    : null;
                if (key && !force) {
                    try {
                        const json = sessionStorage.getItem(key);
                        if (json) {
                            const data = JSON.parse(json);
                            if (data && data.forecast) {
                                this.volumeSpikeData = this.cleanData(data);
                                return;
                            }
                        }
                    } catch(e) {
                        console.warn(e);
                    }
                }

                try {
                    this.loadingSpike = true;
                    this.spikeError = false;
                    const [response, today] = await Promise.all([
                        beef.get(`/api/accounts/${this.account.code}/forecasts/today`, {params: {filter: this.notification.filter}}),
                        grouseCount(`(${this.notification.filter}) and published on today`)
                    ]);

                    // We want to patch in today's actual data as well.
                    if (today?.mentionCount) {
                        const todayData = response.data?.forecast?.find(d => {
                            return moment(d.published).isSame(new Date(), 'day');
                        })
                        if (todayData) todayData.count = today.mentionCount;
                    }

                    this.volumeSpikeData = this.cleanData(response.data);
                    if (key) {
                        try {
                            sessionStorage.setItem(key, JSON.stringify(response.data));
                        } catch(e) {
                            console.warn(e);
                        }
                    }
                } catch (e) {
                    console.error(e);
                    this.spikeError = true;
                } finally {
                    this.loadingSpike = false;
                }
            },

            async createAndAddRecipient(recipient) {
                if (!this.notification.id && this.isDefault) {
                    try {
                        this.updating = true;
                        await this.storeCreateDigest(this.notification)
                    } catch (e) {
                        console.error(e);
                        showErrorDialog("We weren't able to subscribe you to this notification");
                    } finally {
                        this.updating = false;
                    }
                }

                await this.addRecipient(recipient);
            },

            async createAndSubscribe() {
                if (!this.notification.id && this.isDefault) {
                    try {
                        this.updating = true;
                        await this.storeCreateDigest(this.notification)
                    } catch (e) {
                        console.error(e);
                        showErrorDialog("We weren't able to subscribe you to this notification");
                        return;
                    } finally {
                        this.updating = false;
                    }
                }

                await this.subscribe();
            },
            async checkWarnings() {
                const warnings = [];
                const filter = this.notification.filter;
                if (filter) {
                    const noBrandWarning = await checkVolumeSpikeNotifications(this.notification);
                    noBrandWarning.forEach(w => warnings.push(w));
                }

                this.warnings = warnings;
            }
        }
    }
</script>

<style scoped lang="sass">
    .spike-notification
        --border: thin solid #111
        --tag-height: 0em
        transition-property: margin, width, opacity
        transition-duration: calc(var(--transition-duration) * 2)
        position: relative
        display: grid
        grid-template-areas: "header details" "tags details" "image details" "description details" "recipient details" "subscribe details"
        grid-template-columns: 250px 1fr
        grid-template-rows: auto var(--tag-height) minmax(100px, 1fr) minmax(3rem, auto) 2.2em 2.2em
        background: #222
        box-sizing: border-box
        border: thin solid black
        border-radius: 3px
        width: 250px
        color: var(--be-colour-muted-text-dark)
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.4), -2px -2px 2px rgba(100, 100, 100, 0.3)

        &.has-tags
            --tag-height: 2.4em

        &--deleted
            filter: grayscale(0.8)
            background: #2225

        header
            grid-area: header
            border-bottom: var(--border)

            h4
                padding: 10px 5px 10px
                box-sizing: border-box
                margin: 0
                color: var(--be-colour-text-dark)
                height: 100%
                display: flex
                align-items: center
                .spike-notification__name
                    .symbol-edit
                        transition: color var(--transition-duration)
                        opacity: 0
                    &:hover
                        .symbol-edit
                            opacity: 1
                .spike-notification--missing
                    width: 100%
                    .symbol-edit
                        opacity: 1
                .spike-notification__loading
                    margin-left: auto
                    margin-right: 5px
                .rpcs-tag + .spike-notification__name
                    margin-left: 5px
                .spike-notification__name:first-child
                    margin-left: 5px

            .spike-notification__close
                display: none
                position: absolute
                top: 5px
                right: 0
                z-index: 10

            .spike-notification__debug
                display: none
                position: absolute
                top: 7px
                right: 40px
                z-index: 10
                cursor: pointer

            .spike-notification__expand
                transition: opacity 800ms
                animation: alert-notification-expander-pulse 1s ease-in-out both alternate infinite
                position: absolute
                right: -1rem
                opacity: 0
                background: black
                border-radius: 50%
                border: thin solid #333
                box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5)
                height: 2rem
                width: 2rem
                z-index: 10
                cursor: pointer
                &:hover
                    opacity: 1
                &:focus
                    outline: none
                & ::v-deep .be-tooltip
                    width: 100%
                    height: 100%
                    display: flex
                    justify-content: center
                    align-items: center

        .spike-notification__tags
            display: none
            grid-area: tags
            border-bottom: var(--border)
            padding: 5px

        &.has-tags .spike-notification__tags
            display: block

        .spike-notification__image
            grid-area: image
            height: 100%
            border-bottom: var(--border)
        .spike-notification__chart
            width: 100%
            height: 100%
        .spike-notification__image-loader
            width: 100%
            height: 100%
            display: flex
            align-items: center
            justify-content: center
        .spike-notification__description
            grid-area: description
            padding: 5px 10px 5px
            border-bottom: var(--border)
            color: var(--be-colour-text-dark)
            overflow: hidden
            text-overflow: ellipsis
            display: -webkit-box
            -webkit-box-orient: vertical
            //noinspection CssUnknownProperty
            -webkit-line-clamp: 3

            &--empty
                display: flex
                align-items: center
                font-style: italic
                color: var(--be-colour-muted-text-dark)
        .spike-notification__subscribers
            grid-area: recipient
            padding: 5px 10px
            border-bottom: var(--border)
        .spike-notification__subscribe
            grid-area: subscribe
            padding: 5px 10px
            .be-tag
                color: white
            & ::v-deep .btn-link
                padding-left: 0
        .spike-notification__call-to-action
            background: var(--colour-primary-gradient)
            border-bottom-left-radius: 3px
            border-bottom-right-radius: 3px
            color: white
            margin: -3px -7px
            padding: 5px 8px
            box-sizing: border-box
            height: 1.9em
            line-height: 1.1em
            &:hover
                background: var(--background-menu-hover)
                cursor: pointer
        .symbol-checkmark
            color: green
        .spike-notification__details
            transition: opacity var(--transition-duration)
            grid-area: details
            display: none
            overflow: hidden
            max-height: 100%
            border-left: var(--border)
            padding: 10px
            background: #333
            color: var(--be-colour-text-dark)
            .be-tag
                color: white
            &--visible
                overflow: visible
                overflow-y: auto

            h4
                color: var(--be-colour-light-grey)

            .spike-notification__detail-scroll
                overflow-y: auto
                box-sizing: border-box
                flex: 1

            .spike-notification__detail-description
                span
                    transition: color var(--transition-duration)
                    cursor: pointer
                    .symbol-edit
                        transition: opacity var(--transition-duration)
                        opacity: 0
                    &:hover
                        color: var(--be-colour-text-dark__hover)
                .spike-notification__detail-no-description
                    font-style: italic
                    .symbol-edit
                        opacity: 1
                &:hover
                    span
                        .symbol-edit
                            opacity: 1
                &--disabled
                    span
                        cursor: not-allowed

        &:hover
            header
                .spike-notification__expand
                    opacity: 1

        &.expanded
            width: 800px
            z-index: 100

            header
                .spike-notification__debug,
                .spike-notification__close
                    display: block
                    animation: alert-notification-fade-in 250ms 100ms both
                .spike-notification__expand
                    transition: none
                    opacity: 0

            .spike-notification__details
                display: flex
                flex-direction: column
                &:not(.spike-notification__details--no-animation)
                    animation: alert-notification-fade-in 250ms 100ms both

            .spike-notification__call-to-action
                border-bottom-right-radius: 0

        &:focus
            outline: none

    ul
        list-style: none
        margin: 0
        padding: 0 0 0 10px
    li
        padding: 3px 0
        line-height: 1.6em
        position: relative
        .be-tooltip
            max-width: max-content

    .spike-notification__warnings
        display: inline-block
    .spike-notification__warnings ::v-deep .symbol-warning
        font-size: 1em !important

    .spike-notification__danger
        display: flex
        justify-content: flex-end
        border-top: var(--border)
        padding: 5px 5px 0
        margin: auto -10px -5px -10px

        .be-button + .be-button
            margin-left: 5px

    .spike-notification__message
        display: flex
        gap: 10px
        align-items: center
        padding: 5px 10px 0
        margin: auto -10px -5px -10px

        .spike-notification__default-show-advanced
            margin-left: auto


    @keyframes alert-notification-fade-in
        0%
            opacity: 0
        100%
            opacity: 1


    @keyframes alert-notification-expander-pulse
        0%
            transform: scale(1)
        100%
            transform: scale(1.1)


</style>