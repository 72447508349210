<template>
    <div class="beef-tooltip fantastic-chart--tooltip">
        On <strong><deq-date :date="data.published" format="YYYY-MM-DD"/></strong>
        we found <strong><deq-number :number="data.count"/></strong> mentions
        related to
        <span v-if="!isRpcsFlag(tag)">your alert</span>
        <rpcs-tag v-else :code="tag" :full="!name"></rpcs-tag>
        <strong v-if="name"> {{name}}</strong>
        <p>
            Click to see these mentions.
        </p>
    </div>
</template>


<script>
import RpcsTag from "@/components/tags/RpcsTag";
import {isRpcsFlag} from "@/app/utils/Metatags";
import DeqDate from "@/components/formatters/DeqDate";
import DeqNumber from "@/components/formatters/DeqNumber";

export default {
        components: {DeqNumber, DeqDate, RpcsTag},
        props: {
            data: Object,
            tag: String,
            name: String
        },

        methods: {
            isRpcsFlag
        }
    }
</script>

<style scoped lang="sass">

</style>