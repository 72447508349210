<template>
    <div class="notification-bucket-selector">
        <div class="notification-bucket-selector__tools">
            <div class="notification-bucket-selector__options notification-bucket-selector__options--disabled">
                <be-button :disabled="disabled" :active="active === 'AUTO'" @click="setBucket('AUTO')">Automatic</be-button>
                <be-button :disabled="disabled" :active="active === 'DAY'" @click="setBucket('DAY')">Daily</be-button>
                <be-button :disabled="disabled" :active="active === 'HOUR'" @click="setBucket('HOUR')">Hourly</be-button>
            </div>

            <popup-menu ref="amount" v-if="!disabled" bottom>
                <template #activator>
                    <slotted-tag no-close
                                 class="notification-bucket-selector__min-volume"
                                 tooltip="Select the minimum number of mentions that a spike needs to be before an alert is sent. This is good for stopping alerts from being sent for small spikes.">
                        {{notification.minVolume || 1}} {{formatPlural(notification.minVolume || 1, 'mention')}}
                    </slotted-tag>
                </template>


                <section class="mini-menu" style="font-family: var(--number-font-family)">
                    <a @click="setAmount(1)">1</a>
                    <a @click="setAmount(5)">5</a>
                    <a @click="setAmount(10)">10</a>
                    <a @click="setAmount(20)">20</a>
                    <a @click="setAmount(50)">50</a>
                </section>
            </popup-menu>
            <slotted-tag v-else
                         :disabled="disabled"
                         no-close>{{notification.minVolume || 1}} {{formatPlural(notification.minVolume || 1, 'mention')}}</slotted-tag>
        </div>


        <div class="deq-callout--muted">{{explainer}}</div>
    </div>
</template>


<script>

import BeButton from "@/components/buttons/BeButton";
import PopupMenu from "@/components/PopupMenu";
import SlottedTag from "@/components/tags/SlottedTag";
import {formatPlural} from "@/app/utils/Format";

export default {
    components: {SlottedTag, PopupMenu, BeButton},
    props: {
        notification: Object,
        disabled: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        active() {
            return this.notification?.bucket ?? "AUTO";
        },

        explainer() {
            let bucketExplainer = null,
                volumeExplainer = "";
            const minVolume = this.notification?.minVolume ?? 1;

            switch(this.active) {
                case "HOUR":
                    bucketExplainer = "This alert only considers hourly volumes."; break;
                case "DAY":
                    bucketExplainer = "This alert considers daily volumes."; break;
                case "AUTO":
                default:
                    bucketExplainer = "This alert looks at volumes at both an hourly or daily level.";
            }

            if (minVolume > 1) {
                volumeExplainer = `It fires only if there have been ${minVolume} or more mentions.`
            }

            return bucketExplainer + " " + volumeExplainer;
        }
    },

    methods: {
        formatPlural,

        setBucket(value) {
            this.$emit("set-bucket", value);
        },

        setAmount(amount) {
            this.$emit("set-amount", amount);
        }
    }
}

</script>

<style scoped lang="sass">

.notification-bucket-selector__tools
    display: flex
    gap: 5px

.notification-bucket-selector__options
    display: flex

    ::v-deep .btn
        border-radius: 0

    ::v-deep .be-button:first-of-type .btn
        border-bottom-left-radius: 3px
        border-top-left-radius: 3px

    ::v-deep .be-button:last-of-type .btn
        border-bottom-right-radius: 3px
        border-top-right-radius: 3px

.notification-bucket-selector__min-volume
    cursor: pointer

</style>